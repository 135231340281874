import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Default border`}</h2>
    <p>{`The default border utility applies a solid, 1px border, with a default gray color.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--default'
    }]} height="80" mdxType="StorybookEmbed" />
    <p>{`Borders can be applied to a specific edge or to the X and Y axes individually:`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--direction'
    }]} height="340" mdxType="StorybookEmbed" />
    <p>{`Remove borders from all sides or a single side with `}<inlineCode parentName="p">{`.border-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`.border-top-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`.border-right-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`.border-bottom-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`.border-left-0`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--hide'
    }]} height="80" mdxType="StorybookEmbed" />
    <h2>{`Border style`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`border-dashed`}</inlineCode>{` to give an element a dashed border.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--style'
    }]} height="80" mdxType="StorybookEmbed" />
    <h2>{`Responsive borders`}</h2>
    <p>{`You can adjust border widths on all sides or each side individually with responsive border utilities:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`border-(sm|md|lg|xl)`}</inlineCode>{` adds borders on all sides at and above the breakpoint. The `}<inlineCode parentName="li">{`border-(sm|md|lg|xl)`}</inlineCode>{` shorthand is also supported.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`border-(sm|md|lg|xl)-0`}</inlineCode>{` removes borders from all sides at and above the breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`border-(sm|md|lg|xl)-(top|right|bottom|left)`}</inlineCode>{` adds a border on the given side at and above the breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`border-(sm|md|lg|xl)-(top|right|bottom|left)-0`}</inlineCode>{` the border from the given side at and above the breakpoint.`}</li>
    </ul>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--responsive'
    }]} height="150" mdxType="StorybookEmbed" />
    <h2>{`Border colors`}</h2>
    <p>{`Border colors are documented on the `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/colors#border"
      }}>{`colors utilities page`}</a>{`.`}</p>
    <h2>{`Rounded corners`}</h2>
    <p>{`Use the following utilities to add or remove rounded corners: `}<inlineCode parentName="p">{`rounded-0`}</inlineCode>{` removes rounded corners, `}<inlineCode parentName="p">{`rounded-1`}</inlineCode>{` applies a border radius of 4px, `}<inlineCode parentName="p">{`rounded-2`}</inlineCode>{` applies a border radius of 6px, and `}<inlineCode parentName="p">{`rounded-3`}</inlineCode>{` applies a border radius of 8px. `}<inlineCode parentName="p">{`.circle`}</inlineCode>{` applies a border radius of 50%, which turns square elements into perfect circles.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--rounded'
    }]} height="360" mdxType="StorybookEmbed" />
    <p>{`You can also add rounded corners to each edge (top, right, bottom, left) with the following utilities:`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--rounded-direction'
    }]} height="240" mdxType="StorybookEmbed" />
    <h2>{`Responsive rounded corners`}</h2>
    <p>{`You can adjust border radius on all sides or each side individually with responsive border radius utilities:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`rounded-(sm|md|lg|xl)`}</inlineCode>{` adds a 6px rounded corner on all sides at and above the breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`rounded-(sm|md|lg|xl)-(0|1|2|3|4)`}</inlineCode>{` adds a rounded corner on all sides at and above the breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`rounded-(sm|md|lg|xl)-(top|right|bottom|left)-(0|1|2|3|4)`}</inlineCode>{` adds a rounded corner on the given side at and above the breakpoint.`}</li>
    </ul>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-border--rounded-responsive'
    }]} height="200" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      